<template>
  <div class="no-record">
    <img class="imgPath" src="../assets/img/zanwuxiaoxi@2x.png" alt="" />
    <br />
    <p>暂无记录</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.no-record {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  font-size: 16px;
  color: rgb(187, 187, 187);
}
.imgPath {
  width: 136px;
  margin: 90px auto 0;
}
</style>